import axios from "axios";
import { DataHighlightsStock } from "@/models/data-highlights";

export const getDataHightlightsStocks = async (amount: number = 3) => {
    try {
        const res = await axios.get<{ data: DataHighlightsStock[] }>(`/interface/api/universe/7/commentary/?page_size=${amount}`);

        return res.data;
    } catch (err) {
        throw err;
    }
};
