import { useState } from "react";
import { getDataHightlightsStocks } from "@/controllers/data-highlights";
import { DataHighlightsStock } from "@/models/data-highlights";

export const useDataHighlights = (dataHighlights: DataHighlightsStock[]) => {
    const [currentData, setCurrentData] = useState<DataHighlightsStock[]>(dataHighlights);
    const [data, setData] = useState<DataHighlightsStock[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchMoreData = async (amount?: number) => {
        if (!data.length || data.length < amount) {
            try {
                setLoading(true);
                const result = await getDataHightlightsStocks(amount || 10);
                setData(result.data);
                setCurrentData(result.data);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data highlights", error);
            }
        } else {
            setCurrentData(data.slice(0, amount));
        }
    };

    return { data: currentData, fetchMoreData, loading };
};
