import { dividerState, lighterBackgroundColorState, textColorState } from "@/lib/store";
import { Box, Chip, CircularProgress, Divider, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import ReactCountryFlag from "react-country-flag";
import { useRecoilValue } from "recoil";
import { eventFamilies } from "@/lib/vars";
import dayjs from "dayjs";

interface PropsTypes {
    events: any;
}

const Events: FC<PropsTypes> = ({ events }) => {
    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);

    return (
        <Box id="events" sx={{ marginY: 1 }}>
            <Box sx={{ height: "32px", marginY: 1 }}>
                <Divider
                    sx={{
                        "&::before, &::after": {
                            borderColor: dividerColor,
                        },
                    }}
                >
                    <Chip label={"Events"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                </Divider>
            </Box>
            {!events ? (
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                    <CircularProgress size={40} />
                </Box>
            ) : (
                <Box sx={{ height: "calc(100% - 40px)", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {events.map((el, index) => {
                        const colorFamily = Object.keys(eventFamilies).filter((ev) => ev.includes(el.event_type))[0];
                        return (
                            <Box
                                key={`${el.company_id}-${index}`}
                                sx={{
                                    height: "fit-content",
                                    width: "calc(50% - 5px)",
                                    backgroundColor: lighterBackgroundColor,
                                    borderRadius: 2,
                                    display: "grid",
                                    gridTemplateColumns: "50% 25% 25%",
                                    my: 0.5,
                                    padding: 1,
                                    cursor: el.ticker || el.type === "macro" ? "pointer" : "default",
                                }}
                                onClick={() => (el.ticker ? window.open(window.location.origin + "/id/" + el.stock_id, "_self") : el.type === "macro" ? window.open(window.location.origin + "/macro/", "_self") : null)}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginX: 1 }}>
                                    <Tooltip title={el.event_type}>
                                        <Chip
                                            label={el.event_type}
                                            sx={{
                                                border: `2px solid ${colorFamily !== undefined ? eventFamilies[colorFamily]?.[0] : textColor}`,
                                                backgroundColor: `${eventFamilies[colorFamily]?.[1]}11`,
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                                {el.epoch_time && <Typography sx={{ margin: "auto" }}>{dayjs(el.epoch_time).format("HH:mm")}</Typography>}
                                {el.ticker ? (
                                    <Typography color="gray" sx={{ margin: "auto" }}>
                                        {el.ticker}
                                    </Typography>
                                ) : (
                                    el.country_iso && (
                                        <ReactCountryFlag
                                            countryCode={el.country_iso}
                                            svg
                                            style={{
                                                width: "1.5em",
                                                height: "1.2em",
                                                margin: "auto",
                                            }}
                                        />
                                    )
                                )}
                            </Box>
                        );
                    })}
                </Box>
            )}
            {events && events.length === 0 && (
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                    <Typography>No events today</Typography>
                </Box>
            )}
        </Box>
    );
};

export default Events;
