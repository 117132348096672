import { useState } from "react";
import { getMissingLogo } from "@/controllers/stock";

export const useImageHandler = () => {
    const [imgError, setImgError] = useState(false);
    const [blankImg, setBlankImg] = useState<boolean>(false);

    const handleImgError = (security_id: number) => {
        setImgError(true);
        if (security_id) getMissingLogo(security_id);
    };

    const handleImgLoad = (event) => {
        const width = event.target.naturalWidth;
        setBlankImg(width <= 1);
    };

    return { imgError, blankImg, handleImgError, handleImgLoad };
};
