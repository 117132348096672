import { FC } from "react";
import { dividerState, greyDarkerState, greyLighterState, negativeState, positiveState, textColorState, themeState } from "@/lib/store";
import { Box, Chip, CircularProgress, Divider, Tooltip, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { nFormatter } from "@/lib/utils";

interface PropsTypes {
    options: any;
    isDesktop: boolean;
    is4k: boolean;
}

const Options: FC<PropsTypes> = ({ options, isDesktop, is4k }) => {
    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);
    const theme = useRecoilValue(themeState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const greyLighter = useRecoilValue(greyLighterState);

    return (
        <Box id="options" sx={{ marginY: 1, marginBottom: 4 }}>
            <Box sx={{ height: "32px", marginTop: 1, marginBottom: 2 }}>
                <Divider
                    sx={{
                        "&::before, &::after": {
                            borderColor: dividerColor,
                        },
                    }}
                >
                    <Chip label={"Options"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                </Divider>
                <Typography component={"span"} sx={{ fontSize: "12px !important", display: "block", width: "100%", textAlign: "right", color: theme === "dark" ? greyDarker : greyLighter, px: 1 }}>
                    Positive order value %
                </Typography>
            </Box>

            {options.length > 0 ? (
                <Box sx={{ width: "100%", height: "calc(100% - 32px)", display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {options.map((el, index) => {
                        if (index > 7 && !isDesktop) {
                            return;
                        }
                        return (
                            <Tooltip key={`${el.company_id}-${index}`} title={el.name}>
                                <Chip
                                    size="medium"
                                    label={
                                        <Box sx={{ height: "100%", my: 1 }}>
                                            <Typography variant="body2" sx={{ marginRight: 0.5, display: "inline" }}>
                                                {el.ticker}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontWeight: 900, borderRadius: 99, padding: 0.5, border: `1px solid ${textColor}`, display: "inline" }} component={"span"}>
                                                {nFormatter(Number(el.positive_pc))}
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{
                                        width: is4k ? "18%" : isDesktop ? "calc(20% - 10px)" : "calc(25% - 10px)",
                                        paddingY: 2.5,
                                        mx: 0.5,
                                        my: 0.5,
                                        px: 0.2,
                                        backgroundColor: el.positive_pc > 50 ? positive : negative,
                                        color: textColor,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => window.open(window.location.origin + "/id/" + el.stock_id + "/options", "_self")}
                                />
                            </Tooltip>
                        );
                    })}
                </Box>
            ) : (
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                    <CircularProgress size={40} />
                </Box>
            )}
        </Box>
    );
};

export default Options;