import { dividerState, negativeState, positiveState, textColorState } from "@/lib/store";
import { Box, Chip, CircularProgress, Divider, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";

interface PropsTypes {
    shortScores: any;
    is4k: boolean;
    isDesktop: boolean;
    isMobile: boolean;
    isLaptopOrGreater: boolean;
}
const ShortScores: FC<PropsTypes> = ({ shortScores, is4k, isDesktop, isMobile, isLaptopOrGreater }) => {

    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);


    return (
        <Box id="short-scores" sx={{ marginY: 1 }}>
            <Box sx={{ height: "32px", marginY: 1 }}>
                <Divider
                    sx={{
                        "&::before, &::after": {
                            borderColor: dividerColor,
                        },
                    }}
                >
                    <Chip label={"Short Scores"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                </Divider>
            </Box>
            {shortScores.length > 0 ? (
                <Box sx={{ width: "100%", height: "calc(100% - 32px)", display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {(isMobile ? shortScores.slice(0, 4) : shortScores).map((el, index) => (
                        <Tooltip key={`${el.company_id}-${index}`} title={el.company}>
                            <Chip
                                label={
                                    <Box sx={{ height: "100%", my: 1 }}>
                                        <Typography variant="body2" sx={{ display: "inline" }}>
                                            {el.ticker}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: 900, marginLeft: 0.5, borderRadius: 99, padding: 0.5, border: `1px solid ${textColor}`, display: "inline" }}>
                                            {parseInt(el.score)}
                                        </Typography>
                                    </Box>
                                }
                                sx={{
                                    width: is4k ? "18%" : isDesktop ? "calc(20% - 10px)" : isLaptopOrGreater ? "calc(33% - 10px)" : "calc(25% - 10px)",
                                    mx: 0.5,
                                    my: 0.5,
                                    backgroundColor: el.score > 50 ? positive : negative,
                                    color: textColor,
                                    paddingY: 2.5,
                                    cursor: "pointer",
                                }}
                                onClick={() => window.open(window.location.origin + "/id/" + el.stock_id + "/short-interest", "_self")}
                            />
                        </Tooltip>
                    ))}
                </Box>
            ) : (
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                    <CircularProgress size={40} />
                </Box>
            )}
        </Box>
    );
};


export default ShortScores;