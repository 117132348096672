import { dividerState, negativeState, positiveState, textColorState } from "@/lib/store";
import { Box, Chip, CircularProgress, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";

interface PropsTypes {
    stockMovers: any;
    is4k: boolean;
    isDesktop: boolean;
    isLoadingStocks?: boolean;
}

const MarketMovers: FC<PropsTypes> = ({ stockMovers, isLoadingStocks, is4k, isDesktop }) => {
    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const positive = useRecoilValue(positiveState);
    const negative = useRecoilValue(negativeState);

    if (isLoadingStocks)
        return (
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                <CircularProgress size={40} />
            </Box>
        );

    return stockMovers.length > 0 ? (
        <Box id="market-movers">
            <Box sx={{ height: "32px", marginY: 1, position: "sticky" }}>
                <Divider
                    sx={{
                        "&::before, &::after": {
                            borderColor: dividerColor,
                        },
                    }}
                >
                    <Chip label={"Market Movers"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                </Divider>
            </Box>
            <Box sx={{ width: "100%", height: "calc(100% - 32px)", display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                {stockMovers.map(({ id, ticker, priceChange }, index) => {
                    if (index > 7 && !isDesktop) {
                        return;
                    }
                    return (
                        <Chip
                            key={`${ticker}-${index}`}
                            label={<Typography sx={{ my: 1 }}>{ticker + " " + priceChange.toFixed(2)}</Typography>}
                            sx={{ width: is4k ? "18%" : isDesktop ? "calc(20% - 10px)" : "calc(25% - 10px)", mx: 0.5, my: 0.5, backgroundColor: priceChange > 0 ? positive : negative, color: textColor, cursor: "pointer" }}
                            onClick={() => window.open(window.location.origin + "/id/" + id, "_self")}
                        />
                    );
                })}
            </Box>
        </Box>
    ) : null;
};

export default MarketMovers;
