import { dividerState, positiveState, textColorState } from "@/lib/store";
import { Box, Chip, CircularProgress, Divider, Typography } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import styles from "../MorningNote.module.scss";

interface PropsTypes {
    tradingSignals: any;
}

const TradingSignals: FC<PropsTypes> = ({ tradingSignals }) => {

    const dividerColor = useRecoilValue(dividerState);
    const textColor = useRecoilValue(textColorState);
    const positive = useRecoilValue(positiveState);

    return (
        tradingSignals.length > 0 ? (
            <Box id="trading-signals" sx={{ marginY: 1 }}>
                <Box sx={{ height: "32px", marginY: 1 }}>
                    <Divider
                        sx={{
                            "&::before, &::after": {
                                borderColor: dividerColor,
                            },
                        }}
                    >
                        <Chip label={"Trading Signals"} sx={{ backgroundColor: dividerColor, color: textColor }} />
                    </Divider>
                </Box>
                <Box sx={{ height: "calc(100% - 40px)", overflow: "auto", marginY: 1 }}>
                    {tradingSignals.map((el, index) => {
                        return (
                            <Box key={`${el.company_id}-${index}`} sx={{ display: "flex", justifyContent: "space-between", my: 1 }}>
                                <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr", width: "35%" }}>
                                    <Box sx={{ margin: "auto" }}>
                                        <Chip label={el["b/s"]} sx={{ backgroundColor: positive, color: textColor, marginLeft: 1, textTransform: "uppercase" }} />
                                    </Box>
                                    <Box
                                        sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mx: 1, cursor: "pointer" }}
                                        onClick={() => window.open(window.location.origin + "/id/" + el.stock_id, "_self")}
                                    >
                                        <Typography sx={{ color: "gray" }}>{el.ticker}</Typography>
                                        <Typography sx={{ fontWeight: "500", overflowWrap: "anywhere" }}>{el.company}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: "65%" }}>
                                    <Typography className={styles["data-text"]}>{el.desc}</Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        ) : (
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                <CircularProgress size={40} />
            </Box>
        )
    );

};

export default TradingSignals;