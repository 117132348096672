import { StockMoves, StockMovesHeaders } from "@/models/stock-moves";
import { List, ListQueryParams } from "@/models/table";
import axios from "axios";

export const getStockMoves = async (universeId: number, params = {}) => {
    try {
        const res = await axios.get<List<StockMoves, StockMovesHeaders>>(`/API/v2/${universeId}/stocks/list/moves`, { params });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getStockMovesRisers = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, data, universe_id, next_page_url } = params;

    const url = next_page_url || `/interface/api/universe/${universe_id}/moves`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, ...data, days_back: 0, type: "gainers" };

    try {
        const res = await axios.get<List<StockMoves, StockMovesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getStockMovesFallers = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, signal, data, universe_id, next_page_url } = params;

    const url = next_page_url || `/interface/api/universe/${universe_id}/moves`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options, ...data, days_back: 0, type: "fallers" };

    try {
        const res = await axios.get<List<StockMoves, StockMovesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};
