import { formatDate } from "@/lib/utils";
import { LatestMorningNote, MorningNote, MorningNoteHeaders } from "@/models/morning-note";
import { List } from "@/models/table";
import axios from "axios";
import dayjs from "dayjs";

export const getMorningNoteRegions = async () => {
    try {
        const res = await axios.get<{ id: number; region_name: string; }[]>("/API/v2/morning_note_regions");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getMorningNote = async (id: number) => {
    try {
        const res = await axios.get<LatestMorningNote>(`/API/v2/morning_note_id/${id}`);

        res.data.note_date = dayjs.utc(res.data.note_date).format("YYYY-MM-DD");
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getLatestMorningNote = async (regionId: number, position: number = 0, type: number = 1) => {
    try {
        const res = await axios.get<LatestMorningNote>(`/API/v2/morning_note/${regionId}`, {
            params: {
                position,
                type,
            },
        });

        if (typeof res.data === "object" && res.data.note !== undefined) {
            res.data.note_date = dayjs.utc(res.data.note_date).format("YYYY-MM-DD");
            res.data.note_type === null && (res.data.note_type = 1);
            res.data.note = res.data.note.replace(/<a/g, '<a target="_blank"');
        }
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const upsertMorningNote = async (morningNote: LatestMorningNote) => {
    try {
        const res = await axios.post("/API/v2/morning_note_update_create", {
            ...morningNote,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getMorningNoteList = async () => {
    try {
        const res = await axios.get<List<MorningNote, MorningNoteHeaders>>("/API/v2/morning_note_get_list?&dgformat");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getMorningNoteData = async () => {
    try {
        const res = await axios.get("/interface/api/morning_note/data");

        return res.data;

    } catch (err) {
        throw err;
    }
};
